/* eslint-disable no-console */
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  PossibleTypesMap,
  TypePolicies,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

type ApolloClientInitializer = () => ApolloClient<NormalizedCacheObject>;

interface GetApolloClientProps {
  uri?: string;
  token?: string;
  possibleTypes?: PossibleTypesMap;
  typePolicies?: TypePolicies;
}

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError)
    console.error(`[Network error]: ${JSON.stringify(networkError, null, 2)})`);
});

export const getApolloClient = ({
  uri,
  token,
  possibleTypes,
  typePolicies,
}: GetApolloClientProps) =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([
      errorLink,
      new HttpLink({
        uri,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes: possibleTypes ?? {},
      typePolicies: typePolicies ?? {},
    }),
  });

export function initializeGenericApollo(
  apolloClient: ApolloClient<NormalizedCacheObject> | undefined,
  createClient: ApolloClientInitializer,
  initialState: NormalizedCacheObject | null = null,
) {
  const _apolloClient = apolloClient ?? createClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    _apolloClient.cache.restore(data);
  }

  if (typeof window === 'undefined') return _apolloClient;
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}
