import { GoogleTagManager } from '@next/third-parties/google';
import { useRouter } from 'next/router';
import { memo } from 'react';

const GoogleAnalyticsProvider = () => {
  const gtmToken = process.env.GTM_TOKEN;

  const router = useRouter();

  if (!gtmToken || router.isPreview) {
    return null;
  }
  return <GoogleTagManager gtmId={gtmToken} />;
};
export default memo(GoogleAnalyticsProvider);
