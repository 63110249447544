/* eslint-disable no-console */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useMemo } from 'react';

import {
  getApolloClient,
  initializeGenericApollo,
} from '@/utils/apolloHelpers';

import possibleTypes from './possibleTypes.json';
import { typePolicies } from './typePolicies';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let articleApolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const createApolloClient = () =>
  getApolloClient({
    uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    token: process.env.NEXT_PUBLIC_API_TOKEN,
    possibleTypes,
    typePolicies,
  });

const createArticleApolloClient = () =>
  getApolloClient({
    uri: `${process.env.NEXT_PUBLIC_ARTICLE_API_URL}`,
    token: process.env.NEXT_PUBLIC_ARTICLE_API_TOKEN,
  });

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
) {
  return initializeGenericApollo(
    apolloClient,
    createApolloClient,
    initialState,
  );
}

export function initializeArticleApollo(
  initialState: NormalizedCacheObject | null = null,
) {
  return initializeGenericApollo(
    articleApolloClient,
    createArticleApolloClient,
    initialState,
  );
}

export function addApolloState<T extends { props: any }>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: T,
): T {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}

export function useArticleApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeArticleApollo(state), [state]);
}
